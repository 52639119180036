<template>

    <v-container fluid>


        <v-row>
            <v-col class="text-center" sm="12" cols="12" v-if="can(['coffee_admin', 'coffee_cashier','coffee_accountant'])">
                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('invoice')">
                        <v-icon>mdi-qrcode-scan mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('invoices_for_payment')}}</v-card-subtitle>
                    </v-container>
                </v-card>

                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"

                >
                    <v-container class="py-12 justify-center"  @click="menuClick('orders','coffee_house')">
                        <v-icon>mdi-cart-variant mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('orders')}}</v-card-subtitle>
                    </v-container>
                </v-card>

                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['coffee_admin1'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('tables_coffee_shop')">
                        <v-icon>mdi-table-chair mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('tables')}}</v-card-subtitle>
                    </v-container>
                </v-card>

                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['coffee_admin'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('news')">
                        <v-icon>mdi-newspaper-variant-outline mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('menu_news')}}</v-card-subtitle>
                    </v-container>
                </v-card>

                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['coffee_admin'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('setting_coffee_house')">
                        <v-icon>mdi-cog-box mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('setting_coffee_shop')}}</v-card-subtitle>
                    </v-container>
                </v-card>

                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['coffee_admin1'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('coffee_house_table_reserves')">
                        <v-icon>mdi-file-document-edit-outline mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('table_reserves')}}</v-card-subtitle>
                    </v-container>
                </v-card>
                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['coffee_admin'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('menu_category','coffee_house')">
                        <v-icon>mdi-file-pdf-box mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('menu_coffee_house')}}</v-card-subtitle>
                    </v-container>
                </v-card>

                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['coffee_admin'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('certificate')">
                        <v-icon>mdi-ticket-percent-outline mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('menu_certificates')}}</v-card-subtitle>
                    </v-container>
                </v-card>

                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['coffee_admin'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('partner')">
                        <v-icon>mdi-account-supervisor mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('workers')}}</v-card-subtitle>
                    </v-container>
                </v-card>


            </v-col>

            <v-col class="text-center" sm="12" cols="12" v-if="can(['restaurant_admin', 'restaurant_cashier','restaurant_accountant'])">
                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('invoice')">
                        <v-icon>mdi-qrcode-scan mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('invoices_for_payment')}}</v-card-subtitle>
                    </v-container>
                </v-card>
                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"

                >
                    <v-container class="py-12 justify-center"  @click="menuClick('orders','restaurant')">
                        <v-icon>mdi-cart-variant mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('orders')}}</v-card-subtitle>
                    </v-container>
                </v-card>
                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['restaurant_admin'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('tables_restaurant')">
                        <v-icon>mdi-table-chair mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('tables')}}</v-card-subtitle>
                    </v-container>
                </v-card>
                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['restaurant_admin'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('setting_restaurant')">
                        <v-icon>mdi-cog-box mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('setting_restaurant')}}</v-card-subtitle>
                    </v-container>
                </v-card>

                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['restaurant_admin'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('news')">
                        <v-icon>mdi-newspaper-variant-outline mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('menu_news')}}</v-card-subtitle>
                    </v-container>
                </v-card>

                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['restaurant_admin'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('restaurant_table_reserves')">
                        <v-icon>mdi-file-document-edit-outline mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('table_reserves')}}</v-card-subtitle>
                    </v-container>
                </v-card>
                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['restaurant_admin'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('menu_category', 'restaurant')">
                        <v-icon>mdi-file-pdf-box mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('menu_restaurant')}}</v-card-subtitle>
                    </v-container>
                </v-card>
                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['restaurant_admin'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('certificate')">
                        <v-icon>mdi-ticket-percent-outline mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('menu_certificates')}}</v-card-subtitle>
                    </v-container>
                </v-card>
                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['restaurant_admin'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('partner')">
                        <v-icon>mdi-account-supervisor mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('workers')}}</v-card-subtitle>
                    </v-container>
                </v-card>
            </v-col>

            <v-col class="text-center" sm="12" cols="12" v-if="can(['fitness_admin', 'fitness_cashier','fitness_accountant'])">
                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('invoice')">
                        <v-icon>mdi-qrcode-scan mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('invoices_for_payment')}}</v-card-subtitle>
                    </v-container>
                </v-card>
                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"

                >
                    <v-container class="py-12 justify-center"  @click="menuClick('orders','fitness')">
                        <v-icon>mdi-cart-variant mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('orders')}}</v-card-subtitle>
                    </v-container>
                </v-card>
                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['fitness_admin'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('type_service')">
                        <v-icon>mdi-check-circle-outline mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('type_service')}}</v-card-subtitle>
                    </v-container>
                </v-card>

                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['fitness_admin'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('news')">
                        <v-icon>mdi-newspaper-variant-outline mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('menu_news')}}</v-card-subtitle>
                    </v-container>
                </v-card>

                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['fitness_admin'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('setting_fitness')">
                        <v-icon>mdi-cog-box mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('setting_fitness')}}</v-card-subtitle>
                    </v-container>
                </v-card>
                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['fitness_admin'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('trainer')">
                        <v-icon>mdi-account-box-outline mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('trainers')}}</v-card-subtitle>
                    </v-container>
                </v-card>
                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['fitness_admin'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('group_lesson')">
                        <v-icon>mdi-account-multiple-outline mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('group_lessons')}}</v-card-subtitle>
                    </v-container>
                </v-card>
                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['fitness_admin'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('fitness_calendar')">
                        <v-icon>mdi-calendar-month-outline mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('fitness_calendar')}}</v-card-subtitle>
                    </v-container>
                </v-card>
                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['fitness_admin'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('price_subscription')">
                        <v-icon>mdi-cash-check mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('subscriptions')}}</v-card-subtitle>
                    </v-container>
                </v-card>
                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['fitness_admin'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('certificate')">
                        <v-icon>mdi-ticket-percent-outline mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('menu_certificates')}}</v-card-subtitle>
                    </v-container>
                </v-card>
                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['fitness_admin'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('fitness_main')">
                        <v-icon>mdi-home-outline mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('menu_fitness_main')}}</v-card-subtitle>
                    </v-container>
                </v-card>

                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['fitness_admin'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('partner')">
                        <v-icon>mdi-account-supervisor mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('workers')}}</v-card-subtitle>
                    </v-container>
                </v-card>
            </v-col>

            <v-col class="text-center" sm="12" cols="12" v-if="can(['shop_admin', 'shop_cashier','shop_accountant'])">
                <v-card v-if="false" class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('invoice')">
                        <v-icon>mdi-qrcode-scan mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('invoices_for_payment')}}</v-card-subtitle>
                    </v-container>
                </v-card>
                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"

                >
                    <v-container class="py-12 justify-center"  @click="menuClick('orders','shop')">
                        <v-icon>mdi-cart-variant mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('orders')}}</v-card-subtitle>
                    </v-container>
                </v-card>
                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['shop_admin'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('product')">
                        <v-icon>mdi-shopping mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('product')}}</v-card-subtitle>
                    </v-container>
                </v-card>
                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['shop_admin'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('news')">
                        <v-icon>mdi-newspaper-variant-outline mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('menu_news')}}</v-card-subtitle>
                    </v-container>
                </v-card>

                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['shop_admin'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('setting_shop')">
                        <v-icon>mdi-cog-box mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('setting_shop')}}</v-card-subtitle>
                    </v-container>
                </v-card>
                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['shop_admin'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('category_shop')">
                        <v-icon>mdi-folder-text-outline mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('category_shop')}}</v-card-subtitle>
                    </v-container>
                </v-card>
                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['shop_admin'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('category_product')">
                        <v-icon>mdi-folder-outline mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('category_product')}}</v-card-subtitle>
                    </v-container>
                </v-card>
                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['shop_admin'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('certificate')">
                        <v-icon>mdi-ticket-percent-outline mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('menu_certificates')}}</v-card-subtitle>
                    </v-container>
                </v-card>
                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['shop_admin'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('partner')">
                        <v-icon>mdi-account-supervisor mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('workers')}}</v-card-subtitle>
                    </v-container>
                </v-card>
            </v-col>

            <v-col class="text-center" sm="12" cols="12" v-if="can(['cinema_admin', 'cinema_cashier','cinema_accountant'])">
                <v-card v-if="false" class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('invoice')">
                        <v-icon>mdi-qrcode-scan mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('invoices_for_payment')}}</v-card-subtitle>
                    </v-container>
                </v-card>
                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"

                >
                    <v-container class="py-12 justify-center"  @click="menuClick('orders','cinema')">
                        <v-icon>mdi-cart-variant mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('orders')}}</v-card-subtitle>
                    </v-container>
                </v-card>
                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['cinema_admin'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('news')">
                        <v-icon>mdi-newspaper-variant-outline mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('menu_news')}}</v-card-subtitle>
                    </v-container>
                </v-card>
                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['cinema_admin'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('setting_cinema')">
                        <v-icon>mdi-cog-box mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('setting_cinema')}}</v-card-subtitle>
                    </v-container>
                </v-card>
                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['cinema_admin'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('certificate')">
                        <v-icon>mdi-ticket-percent-outline mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('menu_certificates')}}</v-card-subtitle>
                    </v-container>
                </v-card>
                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['cinema_admin'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('partner')">
                        <v-icon>mdi-account-supervisor mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('workers')}}</v-card-subtitle>
                    </v-container>
                </v-card>
            </v-col>

            <v-col class="text-center" sm="12" cols="12" v-if="can(['park_admin', 'park_cashier','park_accountant'])">
                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('invoice')">
                        <v-icon>mdi-qrcode-scan mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('invoices_for_payment')}}</v-card-subtitle>
                    </v-container>
                </v-card>
                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"

                >
                    <v-container class="py-12 justify-center"  @click="menuClick('orders','park')">
                        <v-icon>mdi-cart-variant mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('orders')}}</v-card-subtitle>
                    </v-container>
                </v-card>

                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['park_admin'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('news')">
                        <v-icon>mdi-newspaper-variant-outline mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('menu_news')}}</v-card-subtitle>
                    </v-container>
                </v-card>

                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['park_admin'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('setting_park')">
                        <v-icon>mdi-cog-box mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('setting_park')}}</v-card-subtitle>
                    </v-container>
                </v-card>
                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['park_admin'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('attraction')">
                        <v-icon>mdi-nintendo-switch mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('attraction')}}</v-card-subtitle>
                    </v-container>
                </v-card>

                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['park_admin'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('park_restaurant')">
                        <v-icon>mdi-food-variant mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('restaurant')}}</v-card-subtitle>
                    </v-container>
                </v-card>

                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['park_admin'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('park_restaurant_menu')">
                        <v-icon>mdi-file-pdf-box mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('menu_restaurant')}}</v-card-subtitle>
                    </v-container>
                </v-card>

                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['park_admin'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('certificate')">
                        <v-icon>mdi-ticket-percent-outline mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('menu_certificates')}}</v-card-subtitle>
                    </v-container>
                </v-card>
                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['park_admin'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('partner')">
                        <v-icon>mdi-account-supervisor mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('workers')}}</v-card-subtitle>
                    </v-container>
                </v-card>
            </v-col>

            <v-col class="text-center" sm="12" cols="12" v-if="can(['hotel_admin', 'hotel_cashier','hotel_accountant'])">

                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"

                >
                    <v-container class="py-12 justify-center"  @click="menuClick('orders', 'hotel')">
                        <v-icon>mdi-cart-variant mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('orders')}}</v-card-subtitle>
                    </v-container>
                </v-card>

                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['hotel_admin'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('news')">
                        <v-icon>mdi-newspaper-variant-outline mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('menu_news')}}</v-card-subtitle>
                    </v-container>
                </v-card>

                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['hotel_admin'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('setting_hotel')">
                        <v-icon>mdi-cog-box mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('setting_park')}}</v-card-subtitle>
                    </v-container>
                </v-card>
                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['hotel_admin'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('room')">
                        <v-icon>mdi-google-classroom mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('rooms')}}</v-card-subtitle>
                    </v-container>
                </v-card>

                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['hotel_admin','hotel_cashier'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('reserve_room')">
                        <v-icon>mdi-content-paste mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('reserve_room')}}</v-card-subtitle>
                    </v-container>
                </v-card>

                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['hotel_admin'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('room_type')">
                        <v-icon>mdi-home-group-plus mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('room_type')}}</v-card-subtitle>
                    </v-container>
                </v-card>

                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['hotel_admin'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('certificate')">
                        <v-icon>mdi-ticket-percent-outline mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('menu_certificates')}}</v-card-subtitle>
                    </v-container>
                </v-card>
                <v-card class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                        v-if="can(['hotel_admin'])"
                >
                    <v-container class="py-12 justify-center"  @click="menuClick('partner')">
                        <v-icon>mdi-account-supervisor mdi-48px</v-icon>
                        <v-card-subtitle>{{ $t('workers')}}</v-card-subtitle>
                    </v-container>
                </v-card>
            </v-col>

        </v-row>

        <v-overlay :value="loading" z-index="10" color="primary">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
    </v-container>

</template>
<script>



export default {
    name: "Dashboard",

    data() {
        return {
            loading: false,

        }
    },

    methods: {
        menuClick(name, type){
            if(type !== ''){
                this.$router.push({
                    name: name,
                    params: {type: type}
                })
            }
            else{
                this.$router.push({
                    name: name,
                })
            }
        },

    }
}
</script>
